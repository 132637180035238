<template>
  <div>
    <p
      v-if="success"
      class="flash success"
    >
      {{ success }}
    </p>

    <p
      v-if="error"
      class="flash error"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FlashMessages',

  props: {
    error: {
      type: String,
      default: null
    },

    success: {
      type: String,
      default: null
    }
  }
}
</script>
