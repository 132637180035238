import moment from 'moment'

export const optionalTime = (timeString) => {
  if (timeString === 'TBC') return timeString

  return moment(timeString, 'hh:mm').format('LT')
}

export const optionalDateTime = (dateString, timeString) => {
  if (dateString === null) return 'date/time TBC'

  return `on ${date(dateString)} at ${optionalTime(timeString)}`
}

export const time = (timeString) => {
  return moment(timeString, 'hh:mm').format('LT')
}

export const date = (dateString) => {
  return moment(dateString).format('dddd, D MMMM YYYY')
}

export const iso8601Date = (dateString) => {
  return moment(dateString).format('YYYY-MM-DD')
}
