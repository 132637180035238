<template>
  <div
    v-if="isYgtBooking"
    class="content error"
  >
    <h2>Something went wrong</h2>
    <h3>Please refresh your browser and try again.</h3>
    <br>
    <p>If the problem persists please email <a :href="`mailto:reservations@yourgolftravel.com?subject=E-sign%20Error&body=Esign Path: ${esignPath}`">reservations@yourgolftravel.com</a></p>
  </div>
  <div
    v-else
    class="content error"
  >
    <h1>Oops</h1>
    <p>Something went wrong.</p>
  </div>
</template>

<script>
export default {
  props: {
    isYgtBooking: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    esignPath () {
      if (this.$route) return this.$route.fullPath

      return 'Unknown'
    }
  }
}
</script>
