import { h } from 'vue'
import errorReporter from 'lib/error-reporter'

import NotFound from 'components/errors/NotFound'
import Unauthorized from 'components/errors/UnauthorizedError'
import InternalError from 'components/errors/InternalError'

export default {
  components: {
    NotFound,
    Unauthorized,
    InternalError
  },

  props: {
    error: {
      type: Error,
      required: true
    },
    isYgtBooking: {
      type: Boolean,
      default: false
    }
  },

  created () {
    errorReporter.error(this.error)
  },

  render () {
    switch (this.error.name) {
      case 'HTTPUnauthorizedError':
        return h(Unauthorized)
      case 'HTTPNotFoundError':
        return h(NotFound)
      default:
        return h(InternalError, { isYgtBooking: this.isYgtBooking })
    }
  }
}
