import { createRouter, createWebHashHistory } from 'vue-router'

import NotFound from 'components/errors/NotFound.vue'
import ReservationFormRootComponent from 'components/reservation-form/RootComponent.vue'

function routeToProps (route) {
  return {
    ...route.params,
    ...route.query
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/reservations/:token',
      component: ReservationFormRootComponent,
      props: routeToProps
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFound
    }
  ]
})

export default router
