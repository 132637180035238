import ItineraryItem from './ItineraryItem'

const isBlank = (value) => {
  return !value || value === ''
}

const isBlankOrTbc = (value) => {
  return isBlank(value) || value === 'TBC'
}

export default class TransferItineraryItem extends ItineraryItem {
  constructor (data, container, isYgtBooking) {
    super(data, container, isYgtBooking)
    this.isOneWay = !this.inward_date
  }

  validateOutwardDate () {
    if (this.confirmed && isBlank(this.outward_date)) {
      return { outward_date: ['blank'] }
    }
    return {}
  }

  validateOutwardPickUpTime () {
    if (this.confirmed !== null && this.pick_up_times_required && isBlankOrTbc(this.outward_pick_up_time) && this.outward_pick_up_location !== null) {
      return { outward_pick_up_time: ['blank'] }
    }

    if (this.confirmed && isBlank(this.outward_pick_up_time)) {
      return { outward_pick_up_time: ['blank'] }
    }
    return {}
  }

  validateInwardDate () {
    if (this.confirmed && !this.isOneWay && isBlank(this.inward_date)) {
      return { inward_date: ['blank'] }
    }
    return {}
  }

  validateInwardPickUpTime () {
    if (this.confirmed !== null && this.pick_up_times_required && isBlankOrTbc(this.inward_pick_up_time) && this.inward_pick_up_location !== null) {
      return { inward_pick_up_time: ['blank'] }
    }

    if (this.confirmed && !this.isOneWay && isBlank(this.inward_pick_up_time)) {
      return { inward_pick_up_time: ['blank'] }
    }
    return {}
  }

  validate () {
    return {
      ...super.validate(),
      ...this.validateOutwardDate(),
      ...this.validateOutwardPickUpTime(),
      ...this.validateInwardDate(),
      ...this.validateInwardPickUpTime()
    }
  }
}
