export class HTTPUnauthorizedError extends Error {
  constructor (...args) {
    super(...args)
    this.name = 'HTTPUnauthorizedError'
  }
}

export class HTTPForbiddenError extends Error {
  constructor (...args) {
    super(...args)
    this.name = 'HTTPForbiddenError'
  }
}

export class HTTPNotFoundError extends Error {
  constructor (...args) {
    super(...args)
    this.name = 'HTTPNotFoundError'
  }
}

export class HTTPConflictError extends Error {
  constructor (...args) {
    super(...args)
    this.name = 'HTTPConflictError'
  }
}
