export default {
  originalData: {
    type: Object,
    required: false
  },

  type: {
    type: String,
    required: true
  },

  id: {
    type: String,
    required: true
  },

  supplier_notes: {
    type: String,
    default: ''
  },

  guests: {
    type: Array,
    default: () => ([])
  },

  confirmed: {
    type: Boolean,
    default: false
  },

  locked: {
    type: Boolean,
    default: false
  },

  tbc_ok: {
    type: Boolean,
    default: true
  },

  confirmation_reference: {
    type: String,
    default: ''
  },

  rejection_reason: {
    type: String,
    default: ''
  },

  updateAttribute: {
    type: Function,
    required: true
  },

  deleted_at: {
    type: String,
    default: null
  },

  deletion_confirmed: {
    type: Boolean,
    default: false
  },

  confirmation_reference_required: {
    type: Boolean,
    default: false
  },

  errors: {
    type: Object,
    default: () => ({})
  }
}
