import wretch from 'wretch'
import {
  HTTPUnauthorizedError,
  HTTPForbiddenError,
  HTTPNotFoundError,
  HTTPConflictError
} from './errors'

const BASE_URL = '/api'

export default class ESignService {
  constructor () {
    this.http = wretch()
      .accept('application/json')
      .content('application/json')
      .url(BASE_URL)
      .catcher(401, error => { throw new HTTPUnauthorizedError(error.message) })
      .catcher(403, error => { throw new HTTPForbiddenError(error.message) })
      .catcher(404, error => { throw new HTTPNotFoundError(error.message) })
      .catcher(409, error => { throw new HTTPConflictError(error.message) })
  }

  verifyHmacToken (authorization) {
    return this.http
      .headers({ authorization: JSON.stringify(authorization) })
      .url('/tokens/verify')
      .post()
      .json()
  }

  fetchSupplier (authorization, supplierId) {
    return this.http
      .headers({ authorization: JSON.stringify(authorization) })
      .url(`/suppliers/${supplierId}`)
      .get()
      .json()
  }

  fetchBooking (authorization, bookingReference) {
    return this.http
      .headers({ authorization: JSON.stringify(authorization) })
      .url(`/bookings/${bookingReference}`)
      .get()
      .json()
  }

  fetchItineraryItems (authorization, bookingReference, supplierId) {
    return this.http
      .headers({ authorization: JSON.stringify(authorization) })
      .url(`/bookings/${bookingReference}/suppliers/${supplierId}/itinerary-items`)
      .get()
      .json()
  }

  fetchSupplierPrices (authorization, bookingReference, supplierId) {
    return this.http
      .headers({ authorization: JSON.stringify(authorization) })
      .url(`/bookings/${bookingReference}/suppliers/${supplierId}/supplier-prices`)
      .get()
      .json()
  }

  updateItineraryItems (authorization, bookingReference, supplierId, submissions) {
    return this.http
      .headers({ authorization: JSON.stringify(authorization) })
      .url(`/bookings/${bookingReference}/suppliers/${supplierId}/itinerary-items`)
      .json({ data: submissions })
      .put()
      .json()
  }

  updateConfirmations (authorization, bookingReference, confirmationsById) {
    return this.http
      .headers({ authorization: JSON.stringify(authorization) })
      .url(`/bookings/${bookingReference}/confirmations`)
      .json({ data: confirmationsById })
      .put()
      .json()
  }

  reportError (error) {
    return this.http
      .url('/errors')
      .json({ errors: [error] })
      .post()
      .res()
  }
}
