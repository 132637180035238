<template>
  <div class="invoice-rate">
    <item-header
      :id="supplierId"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :rejection_reason="rejection_reason"
      :show-confirmation="showConfirmation"
      :update-attribute="updateAttribute"
      :has-confirmation-reference="false"
      :header_text="headingTitle"
      :errors="errors"
    />

    <ol class="list supplier-prices">
      <show-component
        v-for="item in supplierPrices"
        :key="item.id"
        v-bind="item"
      />

      <li
        v-for="(supplierPrice, index) in supplierPriceTotals"
        :key="index"
        class="list-item supplier-price"
      >
        <div class="description">
          Supplier subtotal in {{ supplierPrice.currency }}
        </div>

        <div class="total">
          {{ supplierPrice.total }} {{ supplierPrice.currency }}
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import ShowComponent from './ShowComponent'
import ItemHeader from '../ItemHeader'

export default {
  components: {
    ShowComponent,
    ItemHeader
  },

  props: {
    supplierName: {
      type: String,
      required: true
    },

    supplierId: {
      type: String,
      required: true
    },

    supplierPrices: {
      type: Array,
      required: true
    },

    supplierPriceTotals: {
      type: Array,
      required: true
    },

    confirmed: {
      type: Boolean,
      default: false
    },

    locked: {
      type: Boolean,
      default: false
    },

    tbc_ok: {
      type: Boolean,
      default: true
    },

    hasNetRate: {
      type: Boolean,
      default: false
    },

    confirmation_reference: {
      type: String,
      default: ''
    },

    rejection_reason: {
      type: String,
      default: ''
    },

    updateAttribute: {
      type: Function,
      required: true
    },

    showConfirmation: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    headingTitle () {
      const rate_text = this.hasNetRate === true
        ? 'Net rate inclusive of VAT for '
        : 'Invoice Rate for '

      return rate_text + this.supplierName
    },

    isRejected () {
      return this.confirmed === false
    },

    errors () {
      if (!this.isRejected) return {}
      if (this.rejection_reason && this.rejection_reason !== '') return {}
      return {
        rejection_reason: ['blank']
      }
    }
  }
}
</script>
