<template>
  <div class="content error">
    <h1>Unauthorized</h1>
    <p>You are not authorized to view this page.</p>
  </div>
</template>

<script>
export default {}
</script>
