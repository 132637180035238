<template>
  <div class="content error">
    <h1>Not Found</h1>
    <p>The page you are looking for does not exist</p>
  </div>
</template>

<script>
export default {}
</script>
