<template>
  <div
    class="confirmation"
    :class="{ disabled: locked }"
  >
    <div class="selection unconfirm">
      <input
        :id="unconfirmFor"
        :name="confirmFor"
        :checked="unconfirmed"
        :disabled="tbcDisabled"
        type="radio"
        class="input"
        @change="onChange(id, confirmationType, null)"
      >
      <label
        :for="unconfirmFor"
        class="label"
      >TBC</label>
    </div>

    <div class="selection reject">
      <input
        :id="rejectFor"
        :name="confirmFor"
        :checked="rejected"
        :disabled="locked"
        type="radio"
        class="input"
        @change="onChange(id, confirmationType, false)"
      >
      <label
        :for="rejectFor"
        class="label"
      >Rejected</label>
    </div>

    <div class="selection confirm">
      <input
        :id="confirmFor"
        :name="confirmFor"
        :checked="confirmed === true"
        :disabled="locked"
        type="radio"
        class="input"
        @change="onChange(id, confirmationType, true)"
      >
      <label
        :for="confirmFor"
        class="label"
      >Confirmed</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationForm',

  props: {
    id: {
      type: String,
      required: true
    },

    confirmed: {
      type: Boolean,
      default: null
    },

    locked: {
      type: Boolean,
      default: false
    },

    tbc_ok: {
      type: Boolean,
      default: true
    },

    confirmationType: {
      type: String,
      default: 'confirmed'
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  computed: {
    tbcDisabled () {
      return this.locked || !this.tbc_ok
    },

    unconfirmed () {
      return this.confirmed === null
    },

    rejected () {
      return this.confirmed === false
    },

    unconfirmFor () {
      return `unconfirm_${this.id}`
    },

    rejectFor () {
      return `reject_${this.id}`
    },

    confirmFor () {
      return `confirm_${this.id}`
    }
  }
}
</script>
