export default class Authorization {
  constructor (ids, invalidationGroups, bookingReference, timestamp, token) {
    const suppliers = []
    for (let i = 0; i < ids.length; i++) {
      suppliers.push({
        id: ids[i],
        invalidationGroup: invalidationGroups[i]
      })
    }
    this.suppliers = suppliers
    this.bookingReference = bookingReference
    this.timestamp = timestamp
    this.token = token
  }

  ids () {
    return this.suppliers.map(s => s.id)
  }

  invalidationGroups () {
    return this.suppliers.map(s => s.invalidationGroup)
  }

  struct () {
    return {
      supplier_ids: this.ids(),
      invalidation_groups: this.invalidationGroups(),
      booking_reference: this.bookingReference,
      timestamp: this.timestamp,
      token: this.token
    }
  }
}

Authorization.parse = (idString, invalidationGroupString, bookingReference, timestamp, token) => {
  if (
    !idString ||
      typeof invalidationGroupString !== 'string' ||
      !bookingReference ||
      !timestamp ||
      !token
  ) return null

  const ids = idString.split(',')
  const groups = invalidationGroupString.split(',')

  if (ids.length !== groups.length) return null
  return new Authorization(ids, groups, bookingReference, timestamp, token)
}
