export default class Suppliers {
  constructor (data) {
    this.ids = Object.keys(data)
    this.data = data
  }

  withId (supplierId) {
    return this.data[supplierId]
  }

  all () {
    return Object.values(this.data)
  }

  names () {
    return this.all().map(supplier => supplier.name)
  }

  // Returns a hash on supplier_id, running f for each one,
  // f takes (supplierId, supplier)
  byId (f) {
    const results = {}
    for (let i = 0; i < this.ids.length; i++) {
      results[this.ids[i]] = (f(this.ids[i], this.data[this.ids[i]]))
    }
    return results
  }

  // Loop through each supplier running f for each.
  // Waits for each one to finish before running the next.
  // f should be of the shape: f = (supplierId, supplier) => { ... }
  async asyncForEach (f) {
    let processing = true
    let result = null
    for (let i = 0; i < this.ids.length; i++) {
      if (processing) {
        result = await f(this.ids[i], this.data[this.ids[i]]).then(r => {
          return r
        }).catch(e => {
          processing = false
          return e
        })
      } else {
        break
      }
    }
    if (processing) {
      return Promise.resolve(result)
    } else {
      return Promise.reject(result)
    }
  }
}

Suppliers.fetch = (eSignService, authorization, supplierIds) => {
  const promises = supplierIds.map(supplierId => {
    return eSignService.fetchSupplier(authorization, supplierId)
  })
  return Promise.all(promises).then(responses => {
    const suppliers = {}
    for (let i = 0; i < supplierIds.length; i++) {
      suppliers[supplierIds[i]] = responses[i].data
    }
    return new Suppliers(suppliers)
  })
}
