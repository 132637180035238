<template>
  <section
    v-if="deleted_at !== null"
    class="deleted-at-timestamp"
  >
    <p>
      {{ deletedAtTimestampText }}
    </p>
  </section>
</template>

<script>
import { iso8601Date } from 'lib/date-and-time-formats'

export default {
  name: 'DeletedAt',

  props: {
    deleted_at: {
      type: String,
      default: null
    }
  },

  computed: {
    deletedAtTimestampText () {
      return `Cancelled: ${iso8601Date(this.deleted_at)}`
    }
  }
}
</script>
