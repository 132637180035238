<template>
  <div
    v-if="booking"
    class="booking-details table"
  >
    <div class="table-cell">
      <p>
        Booking reference: {{ booking.reference }}
      </p>
      <p>
        Booking date: {{ formattedBookingDate }}
      </p>
      <p>
        Customer: {{ customerText }}
      </p>
      <p v-if="arrivalDateText">
        Arrival date: {{ arrivalDateText }}
      </p>
      <p v-if="salesAgentText">
        Sales Agent: {{ salesAgentText }}
      </p>
      <p v-if="bookingMethodText">
        Booking Details: {{ bookingMethodText }}
      </p>
      <p v-if="offerText">
        Special Offers: {{ offerText }}
      </p>
    </div>

    <div class="table-cell-right">
      <a
        v-if="isYgtBooking"
        class="user-guide"
        target="_blank"
        rel="noopener noreferrer"
        href="/assets/files/E-signUserGuide.pdf"
      >
        User Guide
      </a>

      <button
        class="button print-button"
        @click="printPage"
      >
        Print this page
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { date } from 'lib/date-and-time-formats'

export default {
  name: 'BookingDetails',

  props: {
    booking: {
      type: Object,
      required: true,

      reference: {
        type: String,
        required: true
      },

      customer_name: {
        type: String,
        required: true
      },

      sales_agent: {
        type: String,
        default: null
      }
    },

    itineraryItems: {
      type: Array,
      required: true
    },

    isYgtBooking: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formattedBookingDate () {
      return date(this.booking.booking_date)
    },

    itineraryItemStartDates () {
      return this.itineraryItems.map(({ start_date }) => moment(start_date))
    },

    arrivalDate () {
      // moment.min on an empty array will always return now rather than null,
      // regardless of now not existing in the array. In this case we will just
      // force it to return null.
      if (this.itineraryItemStartDates.length <= 0) return null
      return moment.min(this.itineraryItemStartDates)
    },

    arrivalDateText () {
      if (!this.arrivalDate) return null
      return date(this.arrivalDate)
    },

    customerText () {
      if (!this.booking) return null
      const phone_number = this.booking.customer ? this.phoneText(this.booking.customer.phone) : null
      const email = this.booking.customer ? this.emailText(this.booking.customer.email) : null

      return [this.booking.customer_name, phone_number, email].filter(x => !!x).join(' ')
    },

    salesAgentText () {
      if (!this.booking || !this.validSalesAgent(this.booking.sales_agent)) return null
      const sa = this.booking.sales_agent

      return [sa.first_name, sa.surname, this.phoneText(sa.phone_number)].filter(x => !!x).join(' ')
    },

    bookingMethodText () {
      if (!this.booking || !this.booking.booking_method) return null

      return this.booking.booking_method
    },

    offerText () {
      if (!this.booking || !this.booking.offers) return null

      return this.booking.offers.join(', ')
    }
  },

  methods: {
    phoneText (phone_number) {
      let international = ''

      if (!phone_number) return ''

      if (phone_number.match(/^0/)) {
        international = ' / ' + phone_number.replace(/^0/, '+44 ')
      }
      return '(tel: ' + phone_number + international + ')'
    },

    emailText (email) {
      if (!email) return ''

      return '(email: ' + email + ')'
    },

    validSalesAgent (sales_agent) {
      return sales_agent && sales_agent.first_name !== 'Online'
    },

    printPage () {
      window.print()
    }
  }
}
</script>
