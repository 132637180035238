<template>
  <div :class="type">
    <item-header
      :id="id"
      :header_text="supplier_name"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <section class="section">
      <p class="detail">
        {{ daysText }}
      </p>
      <p class="detail">
        {{ groupText }}
      </p>
      <p class="detail">
        {{ driverText }}
      </p>
      <p class="detail">
        {{ pickUpText }}
      </p>
      <p class="detail">
        {{ dropOffText }}
      </p>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>
      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { inflect } from 'inflection'
import { date, time } from 'lib/date-and-time-formats'
import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt
  },

  props: {
    ...commonProps,

    supplier_name: {
      type: String,
      default: null
    },

    reference: {
      type: String,
      default: null
    },

    group: {
      type: String,
      default: null
    },

    driver: {
      type: String,
      default: null
    },

    pick_up_date: {
      type: String,
      default: null
    },

    pick_up_time: {
      type: String,
      default: null
    },

    pick_up_location: {
      type: String,
      default: null
    },

    drop_off_date: {
      type: String,
      default: null
    },

    drop_off_time: {
      type: String,
      default: null
    },

    drop_off_location: {
      type: String,
      default: null
    },

    days: {
      type: Number,
      default: null
    }
  },

  computed: {
    daysText () {
      return `${this.days} ${inflect('day', this.days)}` +
        ` car hire (ref: ${this.reference})`
    },

    groupText () {
      return `Car type/group: ${this.group}`
    },

    driverText () {
      return `Driver: ${this.driver}`
    },

    pickUpText () {
      return `Pick-up from ${this.pick_up_location}` +
        ` on ${date(this.pick_up_date)}` +
        ` at ${time(this.pick_up_time)}`
    },

    dropOffText () {
      return `Drop-off to ${this.drop_off_location}` +
        ` on ${date(this.drop_off_date)}` +
        ` at ${time(this.drop_off_time)}`
    },

    supplierNotesLines () {
      return this.supplier_notes.split(/\n/g)
    }
  }
}
</script>
