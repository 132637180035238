<template>
  <li class="list-item supplier-price">
    <div class="description">
      {{ descriptionText }}
    </div>

    <div class="total">
      {{ totalText }}
    </div>
  </li>
</template>

<script>
export default {
  props: {
    quantity: {
      type: Number,
      default: null
    },

    description: {
      type: String,
      default: null
    },

    commission: {
      type: String,
      default: null
    },

    currency: {
      type: String,
      default: null
    },

    price: {
      type: String,
      default: null
    },

    total: {
      type: String,
      default: null
    }
  },

  computed: {
    descriptionText () {
      return `${this.quantity} x ${this.description}` +
        ` @ ${this.price} ${this.currency}`
    },

    commissionText () {
      return `(less ${this.commission}% commission)`
    },

    totalText () {
      let totalText = `${this.total} ${this.currency}`
      if (this.commission) totalText += ` ${this.commissionText}`
      return totalText
    }
  }
}
</script>
