<template>
  <span>
    <input
      class="input time-input"
      type="time"
      :value="time"
      @change="onChange($event.target.value)"
    >
    <span
      v-for="error in errorMessages"
      :key="error"
      class="input-error"
    >
      <br>
      {{ error }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'UpdateTime',

  props: {
    date: {
      type: String,
      required: true
    },

    time: {
      type: String,
      required: true
    },

    onChange: {
      type: Function,
      required: true
    },

    errors: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    errorMessages () {
      return this.errors.map(e => {
        switch (e) {
          case 'notWithinTimeWindow':
            return 'Times can only be changed within a 1 hour window. If you need to change the time further than this please reject the item specifying a new time in the rejection reason.'
          default:
            return e
        }
      })
    }
  }
}
</script>
