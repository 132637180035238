<template>
  <span>
    <input
      class="input date-input"
      :class="dateInputClass"
      type="date"
      :value="date"
      @change="onDateChange($event.target.value)"
    >
    at
    <input
      class="input time-input"
      :class="timeInputClass"
      type="time"
      :value="time"
      @change="onTimeChange($event.target.value)"
    >

    <span
      v-if="required"
      class="sub-label"
    >
      (required)
    </span>

    <span
      v-for="error in errorMessages"
      :key="error"
      class="input-error"
    >
      <br>
      {{ error }}
    </span>
  </span>
</template>

<script>
import { humanize } from 'inflection'
export default {
  name: 'UpdateDateTime',

  props: {
    date: {
      type: String,
      default: ''
    },

    time: {
      type: String,
      default: ''
    },

    dateInputClass: {
      type: String,
      default: ''
    },

    timeInputClass: {
      type: String,
      default: ''
    },

    onDateChange: {
      type: Function,
      required: true
    },

    onTimeChange: {
      type: Function,
      required: true
    },

    errors: {
      type: Object,
      default: () => ({})
    },

    required: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    errorMessages () {
      return Object.keys(this.errors).reduce((result, attr) => {
        return result.concat(...this.errorsFor(attr))
      }, [])
    }
  },

  methods: {
    errorsFor (attr) {
      return this.errors[attr].map(e => {
        switch (e) {
          case 'blank':
            return `${humanize(attr, false)} is required`
          default:
            return e
        }
      })
    }
  }
}
</script>
