<template>
  <ol class="list itinerary-items">
    <li
      v-for="item in itineraryItems"
      :key="item.id"
      class="list-item itinerary-item"
      :class="{ cancelled: item.deleted_at !== null }"
    >
      <component
        :is="componentTypeClass(item.type)"
        v-bind="item"
      />
    </li>
  </ol>
</template>

<script>
import { classify } from 'inflection'
import CarHireShowComponent from './CarHireShowComponent'
import CrossingShowComponent from './CrossingShowComponent'
import FlightShowComponent from './FlightShowComponent'
import GolfShowComponent from './GolfShowComponent'
import HotelShowComponent from './HotelShowComponent'
import SpaShowComponent from './SpaShowComponent'
import TransferShowComponent from './TransferShowComponent'
import GolfExtraShowComponent from './GolfExtraShowComponent'
import TourShowComponent from './TourShowComponent'

export default {
  components: {
    CarHireShowComponent,
    CrossingShowComponent,
    FlightShowComponent,
    GolfShowComponent,
    HotelShowComponent,
    SpaShowComponent,
    TransferShowComponent,
    GolfExtraShowComponent,
    TourShowComponent
  },

  props: {
    itineraryItems: {
      type: Array,
      required: true
    }
  },

  methods: {
    componentTypeClass (type) {
      return `${classify(type)}ShowComponent`
    }
  }
}
</script>
