import ItineraryItem from './ItineraryItem'

export default class HotelItineraryItem extends ItineraryItem {
  constructor (data, container, isYgtBooking) {
    super(data, container, isYgtBooking)
    this.branded_header = this.brandedHeader()
  }

  brandedHeader () {
    if (this.isYgtBooking !== true) {
      return 'Hotel Stay: ' + this.hotel_name
    } else {
      return this.hotel_name
    }
  }
}
