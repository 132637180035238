<template>
  <div :class="type">
    <item-header
      :id="id"
      :header_text="numberOfCarsText"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <section class="section">
      <p class="detail">
        {{ referencesText }}
      </p>
    </section>

    <section class="section">
      <h3>Outward</h3>

      <p class="detail">
        {{ outwardLocationsText }}
      </p>
      <p class="detail">
        {{ outwardTimesText }}
      </p>
    </section>

    <section class="section">
      <h3>Inward</h3>

      <p class="detail">
        {{ inwardLocationsText }}
      </p>
      <p class="detail">
        {{ inwardTimesText }}
      </p>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>
      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { inflect } from 'inflection'
import { date, time } from 'lib/date-and-time-formats'
import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt
  },

  props: {
    ...commonProps,

    number_of_cars: {
      type: Number,
      default: null
    },

    origin: {
      type: String,
      default: null
    },

    destination: {
      type: String,
      default: null
    },

    outward_date: {
      type: String,
      default: null
    },

    outward_time: {
      type: String,
      default: null
    },

    outward_check_in: {
      type: String,
      default: null
    },

    inward_date: {
      type: String,
      default: null
    },

    inward_time: {
      type: String,
      default: null
    },

    inward_check_in: {
      type: String,
      default: null
    },

    references: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    numberOfCarsText () {
      return `Crossings for ${this.number_of_cars}` +
        ` ${inflect('car', this.number_of_cars)}`
    },

    referencesText () {
      return `References: ${this.references.join(', ')}`
    },

    outwardLocationsText () {
      return this.locationsText(
        this.origin,
        this.destination
      )
    },

    outwardTimesText () {
      return this.timesText(
        this.outward_date,
        this.outward_time,
        this.outward_check_in
      )
    },

    inwardLocationsText () {
      return this.locationsText(
        this.destination,
        this.origin
      )
    },

    inwardTimesText () {
      return this.timesText(
        this.inward_date,
        this.inward_time,
        this.inward_check_in
      )
    },

    supplierNotesLines () {
      return this.supplier_notes.split(/\n/g)
    }
  },

  methods: {
    locationsText (origin, destination) {
      return `${origin} to ${destination}`
    },

    timesText (travelDate, travelTime, checkInTime) {
      return `departing on ${date(travelDate)}` +
        ` at ${time(travelTime)}` +
        ` (check in before ${time(checkInTime)})`
    }
  }
}
</script>
