import moment from 'moment'

const asString = (object) => {
  return `${object.date} ${object.time}`
}

const asTime = (object) => {
  return moment(asString(object))
}

export default class TeeTime {
  constructor (item, index) {
    this.id = index
    this.tee_time = item.tee_times[this.id]
    this.originalData = item.originalData.tee_times[this.id]
  }

  max () {
    return asTime(this.originalData).add(1, 'hours')
  }

  min () {
    return asTime(this.originalData).subtract(1, 'hours')
  }

  canBeChangedToAnyTime () {
    return this.originalData.time === '00:00'
  }

  isWithinTimeWindow () {
    const time = asTime(this.tee_time)
    return time.isSameOrAfter(this.min()) && time.isSameOrBefore(this.max())
  }

  validateWithinTimeWindow () {
    if (!this.canBeChangedToAnyTime() && !this.isWithinTimeWindow()) {
      return { tee_times: { [this.id]: { time: ['notWithinTimeWindow'] } } }
    }
    return {}
  }

  reset () {
    this.tee_time.time = this.originalData.time
    this.tee_time.invalid = false
    delete this.tee_time.original_time
  }

  update (value) {
    this.tee_time.time = value
    this.tee_time.invalid = !this.isValid()
    if (this.tee_time.invalid) {
      this.tee_time.original_time = this.originalData.time
    } else {
      delete this.tee_time.original_time
    }

    return value
  }

  validate () {
    return this.validateWithinTimeWindow()
  }

  isValid () {
    const errors = this.validate()

    return !Object.keys(errors).length > 0
  }
}
