<template>
  <div class="app">
    <error-boundary>
      <router-view />
    </error-boundary>
    <footer class="footer">
      <p class="generation-time">
        {{ generationTime }}
      </p>
      <p class="git-revision">
        {{ gitRevision }}
      </p>
    </footer>
  </div>
</template>

<script>
import moment from 'moment'
import ErrorBoundary from 'components/errors/Boundary'

export default {
  components: {
    ErrorBoundary
  },

  props: {
    GIT_REVISION: {
      type: String,
      required: true
    }
  },

  computed: {
    generationTime () {
      return `Generated at ${moment.utc().toDate()}`
    },

    gitRevision () {
      return `Software Version: ${this.GIT_REVISION}`
    }
  }
}
</script>
