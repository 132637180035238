import ESignService from 'services/e-sign-service'

const eSignService = new ESignService()

export default {
  error (error) {
    console.error(error)
    return eSignService.reportError({
      name: error.name,
      message: error.message,
      stack: error.stack,
      time: new Date().toJSON()
    })
  }
}
