<template>
  <div :class="type">
    <item-header
      :id="id"
      :header_text="description"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <section class="section">
      <p class="detail">
        {{ quantityDateText }}
      </p>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>

      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { date } from 'lib/date-and-time-formats'

import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt
  },

  props: {
    ...commonProps,

    description: {
      type: String,
      default: null
    },

    quantity: {
      type: Number,
      default: null
    },

    start_date: {
      type: String,
      default: null
    }
  },

  computed: {
    quantityDateText () {
      return `${this.quantity} on ${date(this.start_date)}`
    },

    supplierNotesLines () {
      return this.supplier_notes.split(/\n/g)
    }
  }
}
</script>
