import ItineraryItem from './ItineraryItem'
import TeeTime from 'models/TeeTime'

export default class GolfItineraryItem extends ItineraryItem {
  teeTimes () {
    const timeData = this.tee_times
    if (!timeData) return []

    const range = [...Array(timeData.length).keys()]
    return range.map(i => new TeeTime(this, i))
  }

  validateTeeTimes () {
    let errors = {}
    if (this.confirmed) {
      this.teeTimes().forEach(teeTime => {
        errors = { ...errors, ...(teeTime.validate()) }
      })
    }
    return errors
  }

  validate () {
    return {
      ...super.validate(),
      ...this.validateTeeTimes()
    }
  }

  callbacks (container) {
    return {
      ...super.callbacks(container),

      updateAttribute: (id, attribute, value) => {
        const item = super.callbacks(container).updateAttribute(
          id, attribute, value
        )

        if (attribute === 'confirmed' && value === null) {
          item.teeTimes().forEach(teeTime => teeTime.reset())
        }

        return item
      },

      updateTimeAttribute: (id, timeIndex, collectionName, value) => {
        const item = container.itineraryItems.find(id)

        if (collectionName === 'tee_times') {
          return new TeeTime(item, timeIndex).update(value)
        } else {
          return null
        }
      }
    }
  }
}
