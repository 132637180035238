export default class SupplierPrices {
  constructor (supplierData, supplierIds) {
    this.data = {}
    for (let i = 0; i < supplierIds.length; i++) {
      const reason = supplierData[i].rejection_reason
      this.data[supplierIds[i]] = {
        prices: supplierData[i].supplier_prices,
        totals: supplierData[i].totals,
        net_rate: supplierData[i].net_rate,
        confirmed: supplierData[i].confirmed,
        rejection_reason: reason === undefined ? '' : reason,
        tbc_ok: supplierData[i].tbc_ok
      }
    }
  }

  ids () {
    return Object.keys(this.data)
  }

  hasNetRateFor (supplierId) {
    return this.data[supplierId].net_rate
  }

  pricesFor (supplierId) {
    return this.data[supplierId].prices
  }

  totalsFor (supplierId) {
    return this.data[supplierId].totals
  }

  confirmedFor (supplierId) {
    return this.data[supplierId].confirmed
  }

  setAttributeFor (supplierId, attribute, value) {
    this.data[supplierId][attribute] = value
  }

  tbcOkFor (supplierId) {
    return this.data[supplierId].tbc_ok
  }

  rejectionReasonFor (supplierId) {
    return this.data[supplierId].rejection_reason
  }

  rejectedWithoutReason (supplierId) {
    return this.confirmedFor(supplierId) === false &&
      this.rejectionReasonFor(supplierId) === ''
  }

  anyRejectedWithoutReason () {
    const collectRejectedWithoutReason = (ids, id) => {
      if (this.rejectedWithoutReason(id)) ids.push(id)
      return ids
    }

    return this.ids().reduce(
      collectRejectedWithoutReason, []
    ).length > 0
  }

  confirmationFor (supplierId) {
    const confirmed = this.confirmedFor(supplierId)
    let result = { confirmed }
    if (!confirmed) {
      result = {
        ...result,
        rejection_reason: this.rejectionReasonFor(supplierId)
      }
    }
    return result
  }

  structFor (supplierId) {
    return {
      ...this.confirmationFor(supplierId),
      totals: this.totalsFor(supplierId),
      collection: this.pricesFor(supplierId)
    }
  }
}

SupplierPrices.fetch = (eSignService, authorization, bookingReference, supplierIds) => {
  const promises = supplierIds.map(supplierId => {
    return eSignService.fetchSupplierPrices(
      authorization,
      bookingReference,
      supplierId
    )
  })
  return Promise.all(promises).then(responses => {
    const supplierData = responses.map(
      response => response.data
    )
    return new SupplierPrices(supplierData, supplierIds)
  })
}
