import Base from './Base'

const isBlank = (string) => {
  return string === '' || string === null || string === undefined
}

export default class ItineraryItem extends Base {
  get isRejected () {
    return this.confirmed === false
  }

  // Same as Rails: returns the object as a hash (*not* a string!).
  // Removes extraneous details like the originalData and callbacks
  asJSON () {
    const extraKeys = [
      'originalData',
      'errors',
      ...Object.keys(this.callbacks(null))
    ]
    const data = { ...this }
    extraKeys.forEach(key => delete data[key])
    return data
  }

  isRejectedWithoutReason () {
    return this.isRejected && isBlank(this.rejection_reason)
  }

  isConfirmedWithoutReference () {
    return this.confirmation_reference_required &&
      this.confirmed === true &&
      isBlank(this.confirmation_reference)
  }

  validateRejectedWithoutReason () {
    if (this.isRejectedWithoutReason()) {
      return { rejection_reason: ['blank'] }
    }
    return {}
  }

  validateConfirmedWithoutReference () {
    if (this.isConfirmedWithoutReference()) {
      return { confirmation_reference: ['blank'] }
    }
    return {}
  }

  validate () {
    return {
      ...super.validate(),
      ...this.validateRejectedWithoutReason(),
      ...this.validateConfirmedWithoutReference()
    }
  }

  callbacks (container) {
    return {
      ...super.callbacks(container),

      updateAttribute: (id, attribute, value) => {
        const item = container.itineraryItems.find(id)

        item[attribute] = value

        item.errors = this.validate()

        return item
      }
    }
  }
}
