import { cloneDeep, isEqual } from 'lodash'

export default class Base {
  constructor (data, container, isYgtBooking) {
    Object.assign(this, {
      ...data,
      ...this.callbacks(container)
    })

    // Hotel items are grouped and don't have an `id` property, rather they have an `ids` property, which is an array of
    // the grouped ItineraryItem IDs
    if (this.ids && !this.id) {
      this.id = this.ids.join('_')
    }

    this.isYgtBooking = isYgtBooking
    this.originalData = cloneDeep(data)
    this.errors = {}
  }

  validate () { return {} }

  isValid () {
    const errors = this.validate()
    if (!isEqual(this.errors, errors)) {
      this.errors = errors
    }

    return !Object.keys(errors).length > 0
  }

  callbacks (_container) {
    return {}
  }
}
