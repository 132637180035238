import { h } from 'vue'
import errorReporter from 'lib/error-reporter'
import InternalError from 'components/errors/InternalError'

export default {
  components: {
    InternalError
  },

  props: {
    isYgtBooking: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return { error: null }
  },

  errorCaptured (error, _vm, _info) {
    this.error = error
    errorReporter.error(error)
    return false
  },

  render () {
    if (!this.error) return this.$slots.default()[0]

    return h(InternalError, { isYgtBooking: this.isYgtBooking })
  }
}
